<template></template>

<script setup lang="ts">
const props = defineProps<{
  dismissOnRouteChange?: boolean;
}>();

const nuxtApp = useNuxtApp();
const translate = nuxtApp.$i18n.global.t;
const route = useRoute();
const router = useRouter();
const authStore = nuxtApp.$auth();
const locale = nuxtApp.$i18n.global.locale;

const appStore = nuxtApp.$app();

onMounted(async () => {
  await authStore?.waitUntilRefreshIsComplete();

  if (authStore?.user) {
    return;
  }

  const action = await appStore?.prompt(
    translate("components.namiLoginPrompt.text"),
    {
      icon: "okay",
      preventOutsideClicks: true,
      censorBackground: true,
      dismissOnRouteChange: props.dismissOnRouteChange,
      buttons: {
        back: {
          buttonType: "secondary",
          buttonText: "Go back",
        },
        login: {
          buttonType: "primary",
          buttonText: translate("components.namiLoginPrompt.buttonLogin"),
        },
      },
    },
  );

  switch (action) {
    case "back": {
      router.back();
      break;
    }

    case "login": {
      authStore?.login({
        redirectBackTo: route.fullPath,
        locale: reverseLocaleMap(locale.value),
      });
    }
  }
});
</script>
